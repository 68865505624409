import { Auth, API } from 'aws-amplify';
import { OfferData, DepotData } from './Types';

export const loadDataAPI = async (params: { sk: string, qk: string }) => {
  await Auth.currentCredentials();
  return API.get('supplierapi', '/quote', { queryStringParameters: params });
}

export const submitQuoteAPI = (qk: string, offer: OfferData) => {
  const init = {
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      quote: offer,
      qk: qk,
    }
  }
  return API.post('supplierapi', '/offer', init);
}

export const declineQuoteAPI = (qk: string, reason: string) => {
  const init = {
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      reason: reason,
      qk: qk,
    }
  }
  return API.post('supplierapi', '/decline', init);
}

export const addDepotAPI = (qk: string, depot: DepotData) => {
  const init = {
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      depot: depot,
      qk: qk,
    }
  }
  return API.post('supplierapi', '/addDepot', init);
}

export const getDepotDistanceAPI = (qk: string, depotId: number) => {
  return API.get('supplierapi', '/getDepotDistance', { queryStringParameters: { qk: qk, did: depotId } });
}