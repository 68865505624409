import BookingSummary from './bookingInfo/BookingSummary';
import BookingMap from './bookingInfo/BookingMap';
import Depot from './supplier/Depot';
import Offer from './supplier/Offer';
import { useState } from 'react';
import { QuotePageProp, OfferData } from './Types';

export default function QuotePage({ quoteKey, bookingInfo, tripLegs, depots, submitQuote, declineQuote }: QuotePageProp) {
  const [depotId, setDepotId] = useState(0);

  const submitQuoteIncDepot = (offerData: OfferData) => {
    if (depotId === 0) {
      alert('Please select a depot');
      return;
    }
    offerData.depotId = depotId;
    submitQuote(offerData);
  }

  return (
    <div className="row gy-4">
      <div className="col-md-6 col-sm-12">
        <div className="row gy-4" style={{ display: "grid" }}>
          <div className="col-12">
            <BookingSummary bookingInfo={bookingInfo} tripLegs={tripLegs} />
          </div>
          <div className="col-12">
            <BookingMap mapUrl={bookingInfo.map} />
          </div>
        </div>
      </div>
      <div className="col-md-6 col-sm-12">
        <div className="row gy-4" style={{ display: "grid" }}>
          <div className="col-12">
            <Depot quoteKey={quoteKey} depots={depots} tripLegs={tripLegs} depotId={depotId} setDepotId={setDepotId} />
          </div>
          <div className="col-12">
            <Offer requiredVehicle={bookingInfo.requiredVehicle} submitQuote={submitQuoteIncDepot} declineQuote={declineQuote} />
          </div>
        </div>
      </div>
    </div >
  )
}