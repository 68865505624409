import { useState } from 'react';
import { Form, ButtonGroup, Button, ToggleButton, OverlayTrigger, Popover, Modal } from 'react-bootstrap';
import { OfferProps, OfferData } from '../Types';

const declineReasons = [
  'Don\'t have available vehicles',
  'Not in the right region',
  'Don\'t have that bus class',
  'Don\'t want that kind of job (Purpose)',
  'Date is too soon',
  'Other'
];

const popoverStarRating = (
  <Popover id="popover-star-rating">
    <Popover.Body>
      <p>The following table explains the star ratings.</p>
      <img src="/stars_table.png" alt="Star rating" />
    </Popover.Body>
  </Popover>
);

const popoverSupplierReference = (
  <Popover id="popover-supplier-ref">
    <Popover.Body>
      This is a field for you to put in your own companies quote reference number. We will include this number in the email confirmation for future reference.
    </Popover.Body>
  </Popover>
);

export default function Offer({ requiredVehicle, submitQuote, declineQuote }: OfferProps) {
  const [showDeclineQuoteModal, setDeclineQuoteModal] = useState(false);

  const [vehicleQuality, setVehicleQuality] = useState("0");
  const [capacity, setCapacity] = useState<'' | number>('');
  const [quoteAmount, setQuoteAmount] = useState<'' | number>('');
  const [includeGST, setIncludeGST] = useState<'' | string>('');
  const [notes, setNotes] = useState('');
  const [name, setName] = useState('');
  const [supplierReference, setSupplierReference] = useState('');
  const [declineReasonId, setDeclineReasonId] = useState('*');

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (quoteAmount === '') {
      alert('Quote amount is required');
      return;
    }

    if (includeGST === '') {
      alert('Include GST is required');
      return;
    }

    if (name === '') {
      alert('Name is required');
      return;
    }

    let offer: OfferData = {
      vehicleQuality: vehicleQuality,
      capacity: capacity === '' ? 0 : capacity,
      quoteAmount: quoteAmount,
      includeGST: includeGST === 'Yes',
      notes: notes,
      name: name,
      supplierReference: supplierReference,
    };

    submitQuote(offer);
  }

  const onDecline = () => {
    if (declineReasonId === '*') {
      alert('Please select a decline reason');
      return;
    }
    declineQuote(declineReasons[parseInt(declineReasonId)]);
    setDeclineQuoteModal(false);
  }

  const gstToggleButtons = ['Yes', 'No'].map((value) => (
    <ToggleButton
      key={`gst-${value}`}
      type="radio"
      variant="outline-primary"
      id={`gst-${value}`}
      value={value}
      checked={includeGST === value}
      onChange={(e) => setIncludeGST(e.currentTarget.value)}
    >
      {value}
    </ToggleButton>
  ));

  return (
    <div className="Quote-section px-4 pt-4 pb-5">
      <Form className="Form-section grid row-gap-3" style={{ display: "grid" }} onSubmit={onSubmit}>
        <h5 className="Section-title">Your Offer</h5>
        <div className="row">
          <div className="col">
            <strong>Required Vehicle: </strong> {requiredVehicle}
            <OverlayTrigger trigger="click" placement="bottom" overlay={popoverStarRating}>
              <Button variant="light rounded-circle">?</Button>
            </OverlayTrigger>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <Form.Group controlId="formVehicleQuality">
              <Form.Label>Quality of Vehicle provided</Form.Label>
              <Form.Select value={vehicleQuality} onChange={(event) => setVehicleQuality(event.target.value)}>
                <option value="0">Please select a star rating</option>
                <option value="2">Under 3 stars</option>
                <option value="3">3 Star (or equivalent)</option>
                <option value="4">4 Star (or equivalent)</option>
                <option value="4.5">4 Plus Star (or equivalent)</option>
                <option value="5">5 Star (or equivalent)</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="col-lg-4 col-sm-12">
            <Form.Group controlId="formCapacity">
              <Form.Label>Capacity</Form.Label>
              <Form.Control type="number" value={capacity} placeholder="# of pax" onChange={(event) => setCapacity(parseInt(event.target.value))} />
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Form.Group controlId="formQuoteAmount">
              <Form.Label>Quote amount*</Form.Label>
              <Form.Control type="number" value={quoteAmount} placeholder="Type your quote" onChange={(event) => setQuoteAmount(parseFloat(event.target.value))} required />
            </Form.Group>
          </div>
        </div>
        <div className="row GST-buttons">
          <div className="col">
            <Form.Group controlId="formIncludeGST">
              <Form.Label>Include GST?*</Form.Label>
              <br />
              <ButtonGroup>
                {gstToggleButtons}
              </ButtonGroup>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Form.Group controlId="formNotes">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={notes}
                onChange={(event) => setNotes(event.target.value)}
                placeholder="Is anything not included in the price?"
              />
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Form.Group controlId="formName">
              <Form.Label>Your Name*</Form.Label>
              <Form.Control value={name} onChange={(e) => setName(e.target.value)} placeholder="Type your name" required />
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Form.Group controlId="formSupplierReference">
              <Form.Label>
                Supplier Reference
                <OverlayTrigger trigger="click" placement="right" overlay={popoverSupplierReference}>
                  <Button variant="light rounded-circle">?</Button>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control value={supplierReference} onChange={(event) => setSupplierReference(event.target.value)} placeholder="Reference #" />
            </Form.Group>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <Button className="Submit-quote-button" type="submit">Submit quote</Button>
            <Button className="Decline-quote-button btn-light" onClick={() => setDeclineQuoteModal(true)}>Decline to quote</Button>
          </div>
        </div>
      </Form>
      <Modal show={showDeclineQuoteModal} onHide={() => setDeclineQuoteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Decline Quote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Select aria-label="Decline Reason" value={declineReasonId} onChange={(event) => setDeclineReasonId(event.target.value)}>
            <option value="*">Please select a decline reason</option>
            {declineReasons.map((reason, idx) => (
              <option value={idx} key={`decline-reason-${idx}`}>{reason}</option>
            ))}
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onDecline}>Submit Decline Reason</Button>
          <Button variant="light" onClick={() => setDeclineQuoteModal(false)}>Go Back</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
