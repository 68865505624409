import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import parse from 'html-react-parser';
import './App.scss';
import { declineQuoteAPI, loadDataAPI, submitQuoteAPI } from './Api';
import { ResponseStatus, DefaultMessage } from './Constants';
import QuotePage from './QuotePage';
import Container from 'react-bootstrap/Container';
import { BookingInfo, TripLeg, DepotInfo, OfferData } from './Types';

function App() {
  const [searchParams] = useSearchParams();
  const [quoteKey, setQuoteKey] = useState('');
  const [showMessage, setShowMessage] = useState(true);
  const [message, setMessage] = useState(DefaultMessage.loading);
  const [bookingInfo, setBookingInfo] = useState<BookingInfo>();
  const [tripLegs, setTripLegs] = useState<Array<TripLeg>>([]);
  const [depots, setDepots] = useState<Array<DepotInfo>>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const sk = searchParams.get('sk');
    const qk = searchParams.get('qk');
    if (!sk || !qk) {
      setMessage(DefaultMessage.clientError);
      return;
    }
    setQuoteKey(qk);
    try {
      const response = await loadDataAPI({ sk: sk, qk: qk });
      if (response.status === ResponseStatus.Success) {
        if (!validFetchDataResponse(response)) {
          setMessage(DefaultMessage.serverError);
          return;
        }
        if (response.result.quote_active) {
          setData(response);
        } else {
          setMessage(response.message || DefaultMessage.clientError);
        }
      } else {
        setMessage(response.message || getErrorMessage(response.status));
      }
    } catch (error) {
      setMessage(DefaultMessage.clientError);
    }
  }

  const setData = (response: any) => {
    const data = response.result.data;
    setBookingInfo(data.bookingInfo);
    setTripLegs(data.tripLegs);
    setDepots(data.depots);
    setShowMessage(false);
  }

  const validFetchDataResponse = (response: any) => {
    if (!response.result) {
      return false;
    }

    if (response.result.quote_active) {
      const data = response.result.data;
      if (!data || !data.bookingInfo || data.tripLegs.length < 1 || data.depots.length < 1) {
        return false;
      }
    }
    return true;
  }

  const submitQuote = async (offerData: OfferData) => {
    try {
      const response = await submitQuoteAPI(quoteKey, offerData);
      if (response.status === ResponseStatus.Success) {
        setMessage(response.message || DefaultMessage.onSubmitQuote);
      } else {
        setMessage(response.message || getErrorMessage(response.status));
      }
    } catch (error) {
      setMessage(DefaultMessage.clientError);
    } finally {
      setShowMessage(true);
    }
  }

  const declineQuote = async (declineReason: string) => {
    try {
      const response = await declineQuoteAPI(quoteKey, declineReason);
      if (response.status === ResponseStatus.Success) {
        setMessage(response.message || DefaultMessage.onDeclineQuote);
      } else {
        setMessage(response.message || getErrorMessage(response.status));
      }
    } catch (error) {
      setMessage(DefaultMessage.clientError);
    } finally {
      setShowMessage(true);
    }
  }

  const getErrorMessage = (errorStatus: 400 | 500) => {
    if (errorStatus === ResponseStatus.ClientError) {
      return DefaultMessage.clientError;
    }
    return DefaultMessage.serverError;
  }

  const logoStyle = {
    backgroundImage: `url(${process.env.REACT_APP_LOGO_URL})`
  };

  return (
    <div className="App">
      <Container className="pt-2 pb-4">
        <header className="App-header py-3 mb-4">
          <a href="https://busify.com.au/"><div id="App-logo" style={logoStyle}></div></a>
        </header>
        {showMessage ? (
          <div className="Message">
            {parse(message)}
          </div>
        ) : bookingInfo && <QuotePage quoteKey={quoteKey} bookingInfo={bookingInfo} tripLegs={tripLegs} depots={depots} submitQuote={submitQuote} declineQuote={declineQuote} />}
      </Container>
    </div>
  );
}

export default App;
