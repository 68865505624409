import { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Autocomplete from "react-google-autocomplete";
import { AddDepotProps, DepotAddress } from '../Types';

const autoCompOptions = {
  componentRestrictions: {
    country: process.env.REACT_APP_COUNTRY
  },
  types: ['address']
};

const findAddressComponent = (components: Array<any>, name: string) => {
  const component = components.find(e => e.types.includes(name));
  if (component) {
    return component.long_name;
  }
}

export default function AddDepot({
  visible,
  onAddDepot,
  hideAddDepot,
}: AddDepotProps) {
  const [name, setName] = useState('');
  const [depotAddress, setDepotAddress] = useState<DepotAddress | null>(null);

  const onPlaceSelected = (place: any) => {
    setDepotAddress({
      addr: place.formatted_address,
      region: findAddressComponent(place.address_components, 'administrative_area_level_1'),
      postCode: findAddressComponent(place.address_components, 'postal_code'),
      country: findAddressComponent(place.address_components, 'country'),
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    });
  }

  const onCancel = () => {
    setName('');
    setDepotAddress(null);
    hideAddDepot();
  }

  const onSubmit = () => {
    if (!name) {
      alert('Name is required');
      return;
    }

    if (!depotAddress) {
      alert('Please select an address');
      return;
    }

    if (!depotAddress.addr || !depotAddress.lat || !depotAddress.lng) {
      alert('Invalid address');
      return;
    }

    onAddDepot({ name: name, ...depotAddress });
    setName('');
    setDepotAddress(null);
    hideAddDepot();
  };

  return (
    <Modal show={visible} onHide={hideAddDepot}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Depot</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              value={name}
              onChange={(event) => setName(event.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="address">
            <Form.Label>Address</Form.Label>
            <Autocomplete
              className="AutoCompleteInput"
              apiKey={process.env.REACT_APP_MAPS_API_KEY}
              options={autoCompOptions}
              onPlaceSelected={onPlaceSelected}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onSubmit}>
          Save
        </Button>
        <Button variant="light" onClick={onCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
