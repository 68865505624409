import { BookingSummaryProp } from "../Types";

export default function BookingSummary({ bookingInfo, tripLegs }: BookingSummaryProp) {
  return (
    <div className="Quote-section px-4 pt-4 pb-5 grid row-gap-2">
      <h5 className="Section-title">Booking Summary</h5>
      <div className="col">
        <div className="row">
          <strong>Trip ID: {bookingInfo.tripId}</strong>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-12">
          <strong>Trip Type: </strong>{bookingInfo.tripType}
          <br />
          <strong># of Passengers: </strong>{bookingInfo.passengers}
        </div>
        <div className="col-xl-6 col-lg-12">
          <strong>Purpose: </strong>{bookingInfo.purpose}
          <br />
          <strong># of Bags: </strong> {bookingInfo.bags}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p className="mb-0">
            <strong>Pick Up Address: </strong>
            <br />
            {tripLegs[0].startAddr}
            <br />
            <strong>Pick Up Date/Time: </strong>{new Date(tripLegs[0].startDate).toLocaleString('en-US', { timeZone: 'UTC' })}
          </p>
        </div>
      </div>
      <div className="row">
        {
          tripLegs.slice(1).map((leg, index) => {
            return (
              <div className="col-12 ms-3" key={`stop-${index}`}>
                <p className="mb-0">
                  <strong>Stop {index + 1} Address: </strong>
                  <br />
                  {leg.startAddr}
                  <br />
                  <strong>Pick Up Date/Time: </strong>{new Date(leg.startDate).toLocaleString('en-US', { timeZone: 'UTC' })}
                </p>
              </div>
            )
          })
        }
      </div>
      <div className="row">
        <div className="col">
          <p className="mb-0">
            <strong>Drop Off Address:</strong>
            <br />
            {tripLegs[tripLegs.length - 1].endAddr}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p className="mb-0">
            <strong>Customer Notes: </strong>{bookingInfo.customerNotes}
          </p>
        </div>
      </div>
    </div >
  );
}
