export const ResponseStatus = {
  Success: 200,
  ClientError: 400,
  ServerError: 500,
};

export const DepotDistanceStatus = {
  Calculated: 0,
  Unknown: 1,
  CalculationQueued: 2,
};

export const DefaultMessage = {
  loading: 'Loading Quote Data...',
  clientError: 'Invalid Request',
  serverError: 'Server Error',
  onSubmitQuote: 'Thank you for submitting your offer.\nWe\'ll contact you shortly.',
  onDeclineQuote: 'Quote is now declined',
  addDepotError: 'Failed to add depot',
}