import { ChangeEvent, useState } from 'react';
import { Form, OverlayTrigger, Button, Popover } from 'react-bootstrap';
import AddDepot from './AddDepot';
import { addDepotAPI, getDepotDistanceAPI } from '../Api';

import { ResponseStatus, DepotDistanceStatus, DefaultMessage } from '../Constants';
import { DepotProps, DepotData, DepotInfo } from '../Types';

export default function Depot({ quoteKey, depots, tripLegs, depotId, setDepotId }: DepotProps) {
  const [depotList, setDepotList] = useState(depots);
  const [showAddDepot, setShowAddDepot] = useState(false);

  const tripLegKms = tripLegs.map((leg) => leg.meters).reduce((acc, cur) => acc + cur, 0) / 1000;
  const tripLegMins = tripLegs.map((leg) => leg.seconds).reduce((acc, cur) => acc + cur, 0) / 60;

  const onAddDepot = async (depotData: DepotData) => {
    try {
      const response = await addDepotAPI(quoteKey, depotData);
      if (response.status !== ResponseStatus.Success) {
        alert(response.message || DefaultMessage.addDepotError);
        return;
      }
      setDepotList([...depotList, response.depot]);
      setShowAddDepot(false);
    } catch (error) {
      alert(DefaultMessage.addDepotError);
    }
  }

  const onDepotSelect = async (event: ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === 'addDepot') {
      setDepotId(0);
      setShowAddDepot(true);
    } else {
      // fetch depot distance data if depot distance status is CALCULATION_QUEUED
      const selectedDepotId = parseInt(event.target.value);
      const idx = depotList.findIndex((depot) => depot.id === selectedDepotId);
      const selectedDepot = depotList[idx];
      if (selectedDepot?.distance.status === DepotDistanceStatus.CalculationQueued) {
        try {
          const response = await getDepotDistanceAPI(quoteKey, selectedDepotId);
          if (response.status === ResponseStatus.Success && response.distance?.status !== DepotDistanceStatus.CalculationQueued) {
            const updatedDepot = { ...selectedDepot, ...{ distance: response.distance } };
            let newDepotList = [...depotList];
            newDepotList[idx] = updatedDepot;
            setDepotList(newDepotList);
          }
        } catch (error) {
          // ignore error for now
        }
      }
      setDepotId(selectedDepotId);
    }
  };

  let depotSelectOptions = depotList.map(depot => (
    <option value={depot.id} key={depot.id}>
      {depot.name} ({depot.address})
    </option>
  ));

  let tripDistanceDetails = tripLegs.map((leg, idx) => (
    <span key={`trip-distance-${idx}`}>
      <strong>Leg {idx + 1} ({idx === 0 ? 'Pick Up' : `Stop ${idx}`} to Stop {idx + 1}): </strong>{(leg.meters / 1000).toFixed(2)} km
      < br />
    </span>
  ));

  const depotDistance = (depot: DepotInfo | undefined) => {
    if (!depot) return;
    if (depot.distance.status === DepotDistanceStatus.CalculationQueued) {
      return (<div className="mt-2"><p>Distance calculation for <strong>Depot {depot.name}</strong> is pending</p></div >);
    }
    if (depot.distance.status === DepotDistanceStatus.Unknown || !depot.distance.toPickUp || !depot.distance.fromDropOff) {
      return (<div className="mt-2"><p>Distance for <strong>Depot {depot.name}</strong> unknown</p></div >);
    }

    const totalKms = tripLegKms + parseFloat(depot.distance.toPickUp.kms) + parseFloat(depot.distance.fromDropOff.kms);
    const totalTime = tripLegMins + parseFloat(depot.distance.toPickUp.mins) + parseFloat(depot.distance.fromDropOff.mins);
    const totalHrs = Math.floor(totalTime / 60);
    const totalMins = Math.round(totalTime % 60);

    return (
      <div className="row mt-2">
        <div className="col-12">
          <p>
            <strong>Depot {depot.name} to Pick Up Address: </strong>{parseFloat(depot.distance.toPickUp.kms).toFixed(2)} km
            <br />
            {tripDistanceDetails}
            <strong>Drop Off Address to Depot {depot.name}: </strong>{parseFloat(depot.distance.fromDropOff.kms).toFixed(2)} km
          </p>
        </div>
        <div className="col-12">
          <p>
            <strong>Depot to Depot Total KM: </strong>{totalKms.toFixed(2)} km
            <br />
            <strong>Depot to Depot Total Elapsed Time: </strong>{totalHrs} hrs {totalMins} mins
          </p>
        </div>
      </div>
    )
  }

  const popover = (
    <Popover id="popover-depot">
      <Popover.Body>
        We have prepopulated your depots. Please select the depot you will use
        the bus from. If the depot does not exist in the list you can add a
        new depot address.
      </Popover.Body>
    </Popover>
  );

  const hideAddDepot = () => {
    setShowAddDepot(false);
  }

  return (
    <div className="Quote-section px-4 pt-4 pb-5">
      <div className="Form-section grid row-gap-2">
        <h5 className="Section-title">Depots and Distances</h5>
        <div className="row">
          <div className="col">
            <Form.Label>
              Select your Depot
              <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                <Button variant="light rounded-circle">?</Button>
              </OverlayTrigger>
            </Form.Label>
            <Form.Select
              aria-label="Select Depot"
              value={depotId}
              onChange={onDepotSelect}
            >
              <option value="0">Please select a depot</option>
              {depotSelectOptions}
              <option value="addDepot">Add New Depot</option>
            </Form.Select>
          </div>
        </div>
        {depotDistance(depotList.find((depot) => depot.id === depotId))}
      </div>
      <AddDepot visible={showAddDepot} onAddDepot={onAddDepot} hideAddDepot={hideAddDepot} />
    </div>
  );
}
